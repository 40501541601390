<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('f_management.investment_subtitle') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <datatable-dropdown/>
            </div>
          </div>
          <div class="card-body">
              <Datatable
                  ref="datatable"
                  :url="datatableUrl"
                  :dataUrl="datatableDataUrl"
                  @data="loadDatatableData($event)"
              >
                  <!-- <template v-slot:head>
                      Table head özelleştirme
                  </template> -->
                  <template v-slot:body>
                    <tr v-for="(item, index) in datatableData" :key="'deposit-' + index">
                      <th scope="row">{{ item.id }}</th>
                      <td>
                        <router-link
                          :to="`/finance/deposit/${item.id}`"
                          class="text-primary font-weight-bold"
                        >
                          {{ item.code }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="`/users/${item.user_id}/overview`"
                          class="text-primary font-weight-bold"
                        >
                          {{ item.fullname }}
                        </router-link>
                      </td>
                      <td>{{ $t(`service.${item.provider}`) }}</td>
                      <td>{{ item.currency }}</td>
                      <td>{{ item.amount }}</td>
                      <td><span class="badge" :class="statusClass(item.status)">{{ statusLabel(item.status) }}</span></td>
                      <td v-html="item.created_at"></td>
                      <td v-html="item.completed_at"></td>
                      <td>
                        <div class="d-flex flex-shrink-0">
                          <button @click="showDetailsModal(item.id)" v-if="checkPermission('crm.finance.deposit.modal')" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('commons.details')">
                            <i class="flaticon2-document"></i>
                          </button>
                          <button @click="showPastTransactionsModal(item.id)" v-if="checkPermission('crm.finance.deposit.history')" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('employees.past_transactions')">
                            <i class="fa fa-history"></i>
                          </button>
                          <!-- if item.status !== 10 -->
                          <button @click="updateStatus(item.id, '10')" v-if="checkPermission('crm.finance.deposit.update-status') && +item.status !== 10" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('f_management.re_approve')">
                            <i class="fa fa-backward"></i>
                          </button>
                          <!-- if item.status !== 40 -->
                          <button @click="updateStatus(item.id, '40')" v-if="checkPermission('crm.finance.deposit.cancel') && +item.status !== 40" class="btn btn-icon btn-hover-icon-dark btn-clean btn-bg-light btn-sm mr-1" :title="$t('f_management.cancel_transaction')">
                            <i class="fa fa-ban text-danger"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </template>
              </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <DetailsModal />

    <SmsModal @depositSmsSentSuccess="initDatatable" />
    <SmsFinanceModal @depositSmsFinanceSentSuccess="initDatatable" />
    
    <AuditModal v-if="audit_modal_visible" ref="auditModal" :close="() => $store.commit('depositTransaction/TOGGLE_AUDIT_MODAL', false)">
      <template v-slot:header>
        <h1>{{ $t('employees.audit_modal_title') }}</h1>
      </template>
      <template v-slot:body>
        <table class="table table-bordered collapsed table-head-bg" style="width:100%">
          <thead>
            <tr>
              <th scope="col">{{ $t('employees.audit_user') }}</th>
              <th scope="col">{{ $t('employees.audit_area') }}</th>
              <th scope="col">{{ $t('employees.audit_old_value') }}</th>
              <th scope="col">{{ $t('employees.audit_new_value') }}</th>
              <th scope="col">{{ $t('employees.audit_date') }}</th>
            </tr>
          </thead>
          <tbody v-for="(auditItem, index) in audits" :key="'audit-' + index">
            <tr v-for="(newValKey, newValKeyIndex) in Object.keys(auditItem.new_values)" :key="'newValKeyIndex-' + newValKeyIndex">
              <td scope="row"><span class="truncateText">
                <template v-if="auditItem.user">
                  <a href="javascript:;" @click="goToUserDetails(auditItem.user.id)">
                    {{ auditItem.user['name'] }} {{ auditItem.user['surname'] }}
                  </a>
                </template>
                </span>
              </td>
              <td scope="row"><span class="truncateText">{{ newValKey }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.old_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.new_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.updated_at }}</span></td>
            </tr>
          </tbody>
        </table>
      </template>
    </AuditModal>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import 'bootstrap-daterangepicker';
import Swal from 'sweetalert2';
import AuditModal from '@/components/custom/AuditModal.vue';
import DetailsModal from '@/components/financialManagement/deposit/DetailsModal.vue';
import SmsModal from '@/components/financialManagement/deposit/SmsModal.vue';
import SmsFinanceModal from '@/components/financialManagement/deposit/SmsFinanceModal.vue';
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";
import $ from "jquery";

export default {
  name: 'DepositTransactions',
  data() {
    return {
      isLoading:true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    DatatableDropdown,
    AuditModal,
    DetailsModal,
    SmsModal,
    SmsFinanceModal,
    Datatable,
  },
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
      audits: state => state.depositTransaction.audits,
      audit_modal_visible: state => state.depositTransaction.audit_modal_visible
    }),
    breadcrumb_data() {
      return {
        title: this.$t('f_management.title'),
        subtitle: [
          this.$t('f_management.deposit_transactions')
        ]
      }
    }
  },
  methods: {
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    checkPermission(permission) {
      return this.permissions?.some(val => val.name == permission);
    },
    getTimeForParsedDate(rawDate) {
        const dateArray= rawDate.split("/");  // "18/02/2022"(DD/MM/YYYY) - convert to "2022-02-18"(YYYY-MM-DD)
        const parsedDate= `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
        return new Date(parsedDate).getTime();
    },
    showPastTransactionsModal(id) {
      this.$store.dispatch('depositTransaction/GET_AUDITS', id);
    },
    goToUserDetails(user_id) {
      this.$store.commit('depositTransaction/TOGGLE_AUDIT_MODAL', false);
      this.$router.push({ path: `/users/${user_id}` })
    },
    showDetailsModal(id) {
      this.$store.dispatch('depositTransaction/GET_DETAILS_MODAL', id)
      this.$store.commit('depositTransaction/TOGGLE_DETAILS_MODAL', true);
    },
    statusClass(status) {
        switch (status) {
            case 10:
                return 'badge-warning';
            case 20:
            case 70:
                return 'badge-light';
            case 30:
                return 'badge-success';
            case 40:
            case 50:
            case 60:
                return 'badge-danger';
            default:
                return '';
        }
    },
    statusLabel(status) {
        if(status) {
            return this.$t(`f_management.statuses.${status}`)
        }
        return '-'
    },
    updateStatus(id, status) {
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.approve"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.$store.dispatch('depositTransaction/UPDATE_STATUS', { id, status })
            .then(() => {
              vm.initDatatable();
            })
            .catch(() => {})
        }
      });
      
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    },
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.finance.deposit.index')) {
      this.$router.push('/no-permission')
    }
    this.datatableUrl = api_old.deposit_datatable;
    this.datatableDataUrl = api_old.deposit_datatable_data;
    if(this.$route.query?.user) {
      this.datatableDataUrl = `${api_old.deposit_datatable_data}?user_id=${this.$route.query.user}`
    }

    const self = this;
    self.$nextTick(function() {
      $("#printExportItem").hide();
      $("#copyExportItem").hide();
      $("#excelExport").on("click", function (e) {
        e.preventDefault();
        let data = { url: `financecrm/deposit/export`, label: `deposit-transactions.xlsx`, reqPayload: self.$refs.datatable.selectedFilters };
        self.$store.dispatch('global/GET_EXCEL', data);
      });
      $("#csvExportItem").hide();
      $("#pdfExportItem").hide();
    })
    
  },
  watch: {
      $route: function (val) {
          if(!val.query?.user) {
              this.datatableDataUrl = api_old.deposit_datatable_data;
          } else {
              this.datatableDataUrl = `${api_old.deposit_datatable_data}?user_id=${val.query.user}`
          }
      }
  }
}
</script>
<style lang="scss" scoped>
  .truncateText {
    min-width: 150px;
    overflow: hidden;
    position: relative;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
</style>
